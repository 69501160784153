import { ReactComponent as Asset } from '../assets/LogoPrimaryAS.svg';
import React from 'react';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

const LogoPrimary = (props: {style?: CSSProperties; className?: string}) => {
    return (
        <Asset className={props.className} />
    );
}

export { LogoPrimary };