import React, { useState, useEffect } from 'react';
import HaAppBar from './components/appbar';
import { Container, Hidden, Drawer, makeStyles, Theme, createStyles, useTheme, Divider, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import HomeView from './views/HomeView';
import Contact from './views/Contact';
import Prices from './views/Prices';
import About from './views/About';
import Why from './views/Why';
import Home from '@material-ui/icons/Home';
import AttachMoney from '@material-ui/icons/AttachMoney';
import Info from '@material-ui/icons/Info';
import Phone from '@material-ui/icons/Phone';
import HelpIcon from '@material-ui/icons/Help';
import { Route, Switch, Redirect, NavLink } from 'react-router-dom';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    icon: {
      color: theme.palette.primary.main,
    },
  }),
);

function App() {

  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const classes = useStyles();

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  }

  const drawer = (
    <div>
      <ScrollToTop />
      <div className={classes.toolbar} />
      <Divider />
      <List>
        <NavLink to="/" style={{ textDecoration: 'none', color: 'inherit' }} >
          <ListItem button key="Hjem">
            <ListItemIcon><Home className={classes.icon} /></ListItemIcon>
            <ListItemText primary={"Hjem"} />
          </ListItem>
        </NavLink>
        <NavLink to="/contact" style={{ textDecoration: 'none', color: 'inherit' }} >
          <ListItem button key="Bestill">
            <ListItemIcon><Phone className={classes.icon} /></ListItemIcon>
            <ListItemText primary={"Bestill"} />
          </ListItem>
        </NavLink>
        <NavLink to="/prices" style={{ textDecoration: 'none', color: 'inherit' }} >
          <ListItem button key="Priser">
            <ListItemIcon><AttachMoney className={classes.icon} /></ListItemIcon>
            <ListItemText primary={"Priser"} />
          </ListItem>
        </NavLink>
        <NavLink to="/about" style={{ textDecoration: 'none', color: 'inherit' }} >
          <ListItem button key="Om oss">
            <ListItemIcon><Info className={classes.icon} /></ListItemIcon>
            <ListItemText primary={"Om oss"} />
          </ListItem>
        </NavLink>
        <NavLink to="/why" style={{ textDecoration: 'none', color: 'inherit' }} >
          <ListItem button key="Info">
            <ListItemIcon><HelpIcon className={classes.icon} /></ListItemIcon>
            <ListItemText primary={"Info"} />
          </ListItem>
        </NavLink>
      </List>
    </div >
  );

  return (
    <div className="App">
      <HaAppBar handleClick={handleDrawerToggle} />
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={drawerOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            onClick={handleDrawerToggle}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <Container maxWidth='md'>
        <Switch>
          <Route exact path="/" component={HomeView} />
          <Route path="/contact" component={Contact} />
          <Route path="/prices" component={Prices} />
          <Route path="/why" component={Why} />
          <Route path="/about" component={About} />
          <Redirect to="/" />
        </Switch>
      </Container>
      <Footer />
    </div>
  );
}

export default App;
