import { Grid, makeStyles, createStyles, Theme, Container } from "@material-ui/core";
import React from 'react';
import HaCard, { HaCardProps } from "../components/HaCard";
import { LogoPrimaryBobSecondary } from "../components/LogoPrimaryBobSecondary";
import { prices } from '../prices';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        topHomeLogo: {
            width: '100%',
            height: 'auto',
        },
        container: {
            paddingTop: '24px',
            paddingBottom: '24px',
            paddingLeft: '0px',
            paddingRight: '0px'
        },
        logoContainer: {
            paddingTop: '24px',
            paddingBottom: '24px'
        }
    })
);

export default function HomeView() {
    const classes = useStyles();

    const contactInfoProps: HaCardProps = {
        link: '/contact',
        image: 'Contact.svg',
        imageAlt: 'Kontakt Informasjon',
        imageTitle: 'Kontakt Informasjon',
        title: 'Bestill',
        text: 'Kontakt oss på telefon for en uforpliktende samtale eller bestilling.  \ntlf: <b>952 10 855</b>'
    };

    const priceInfoProps: HaCardProps = {
        link: '/prices',
        image: 'Wallet.svg',
        imageAlt: 'Priser',
        imageTitle: 'Priser',
        title: 'Priser',
        text: `Vi tilbyr rimelig og rask behandling. Prisen varierer etter størrelsen på bilen, og starter på ${prices.small},- NOK inkl. mva.`,
    };

    const whyInfoProps: HaCardProps = {
        link: '/why',
        image: 'Piggybank.svg',
        imageAlt: 'Spar penger',
        imageTitle: 'Spar penger',
        title: 'Ta vare på bilen din',
        text: 'Ved å beskytte bilen din med lanolin, bevarer du verdien på bilen din og sparer penger! Sørg for at bilen din er klar for norske, saltede veier!'
    };

    return (
        <Container className={classes.container}>
            <Grid container spacing={3}>
                <Grid item sm={12}>
                    <Container maxWidth='md' className={classes.logoContainer}>
                        <LogoPrimaryBobSecondary className={classes.topHomeLogo} />
                    </Container>
                </Grid>
                <Grid item sm={12} md={6}>
                    <HaCard {...contactInfoProps} />
                </Grid>
                <Grid item sm={12} md={6}>
                    <HaCard {...priceInfoProps} />
                </Grid>
                <Grid item sm={12} md={6}>
                    <HaCard {...whyInfoProps} />
                </Grid>
            </Grid>
        </Container>
    );
}