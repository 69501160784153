import { makeStyles, createStyles, CardActionArea, CardContent, Typography, CardActions, Button, Card, CardMedia, Theme } from "@material-ui/core";
import React from "react";
import Markdown from 'markdown-to-jsx';
import { Link } from "react-router-dom";

export interface HaCardProps {
    image: string,
    imageAlt: string,
    imageTitle: string,
    title: string,
    text: any,
    link: string,
}

export default function HaCard(props: HaCardProps) {
    const classes = useStyles();


    return (
        <Card className={classes.cardRoot}>
            <Link to={props.link} style={{textDecoration: 'none', color: 'inherit'}}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        alt={props.imageAlt}
                        height="150"
                        image={props.image}
                        title={props.title}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            {props.title}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            <Markdown options={{ forceInline: true }} children={props.text} />
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Link>
            <CardActions>
                <Link to={props.link} style={{textDecoration: 'none', color: 'inherit'}}>
                    <Button size="small" color="secondary" variant="contained">
                        Les mer
                </Button>
                </Link>
            </CardActions>
        </Card>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardRoot: {
            // maxWidth: 345,
        }
    }),
)