import { ReactComponent as AssetSvg } from '../assets/suv.svg';
import React from 'react';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

const Suv = (props: {style?: CSSProperties; className?: string}) => {
    return (
        <AssetSvg className={props.className} />
    );
}

export { Suv };