import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import { BobSecondary } from './BobSecondary';
import { Link } from 'react-router-dom';

interface HaAppBarProps {
  handleClick: Function
}

export default function HaAppBar(props: HaAppBarProps) {
  const classes = useStyles();

  return (
      <AppBar position="relative" className={classes.appbarRoot}>
        <Toolbar>
          <div className={classes.wrap}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={() => {props.handleClick()}}
            >
            <MenuIcon />
            </IconButton>
            <Link to="/" className={classes.title} style={{textDecoration: 'none', color: 'inherit'}}>
              <Typography className={classes.title} variant="h6" noWrap>
              Hellestranda Antirust AS
              </Typography>
            </Link>
          <BobSecondary className={classes.bobIcon} />
          </div>
        </Toolbar>
      </AppBar>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appbarRoot: {
      zIndex: theme.zIndex.drawer + 1,
    },
    wrap: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      alignContent: 'center',
    },
    testColor: {
      backgroundColor: 'red'
    },
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      display: 'block',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    bobIcon: {
      height: '50px',
      width: '50px',
    }
  }),
);

