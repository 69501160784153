import { Grid, makeStyles, createStyles, Theme, Container, List, ListItem, ListItemText, ListItemAvatar, Typography, Card, CardMedia, CardContent, Avatar } from "@material-ui/core";
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        topHomeLogo: {
            width: '100%',
            height: 'auto',
        },
        container: {
            paddingTop: '24px',
            paddingBottom: '24px',
            paddingLeft: '0px',
            paddingRight: '0px'
        },
        icon: {
            height: theme.spacing(9),
            width: theme.spacing(9),
        },
        listItemAvatar: {
            width: theme.spacing(11)
        },
        image: {
            maxHeight: '400px',
            objectFit: 'contain'
        }
    })
);

export default function Why() {
    const classes = useStyles();

    const list = (
        <List>
            <ListItem key="location" alignItems="flex-start">
                <ListItemAvatar className={classes.listItemAvatar}><Avatar src="rustedCar.jpg" className={classes.icon}/></ListItemAvatar>
                <ListItemText
                    primary={"Hvorfor?"}
                    secondary={
                        "De fleste biler er dårlig, eller ikke understellsbehandlet når de er ny." +
                        " Alle biler ruster, og de ruster kanskje spesielt mye på saltede norske veier."
                    }
                />
            </ListItem>
            <ListItem key="contact" alignItems="flex-start">
                <ListItemAvatar className={classes.listItemAvatar}><Avatar src="sheep.jpg" className={classes.icon}/></ListItemAvatar>
                <ListItemText
                    primary={"Hvorfor akkurat denne behandlingen?"}
                    secondary={
                        "Behandlingen vi tilbyr er rimelig, rask, trygg og miljøvennlig." +
                        " Stoffet vi bruker er basert på lanolin fra saueull." +
                        " Lanolin ble tidligere brukt i sjøvannstanker på krigsskip og hindrer rust svært godt."
                    }
                />
            </ListItem>
            <ListItem key="price" alignItems="flex-start">
                <ListItemAvatar className={classes.listItemAvatar}><Avatar src="invest.svg" className={classes.icon}/></ListItemAvatar>
                <ListItemText
                    primary={"Bør jeg behandle min bil hos dere?"}
                    secondary={
                        "Ja! En ny bil bør behandles så fort som mulig for å forhindre rustangrep." +
                        " En gammel bil som allerede har startet å ruste, bør behandles for å sinke eller stoppe rustutviklingen." +
                        " Å behandle bilen din hos oss kan ansees som en liten investering for å bevare bilens verdi og øke levetiden til understellsdeler."
                    }
                />
            </ListItem>
            <ListItem key="how" alignItems="flex-start">
                <ListItemAvatar className={classes.listItemAvatar}><Avatar src="fluidfilm.jpg" className={classes.icon}/></ListItemAvatar>
                <ListItemText
                    primary={"Hva gjør dere?"}
                    secondary={
                        "Vi bruker et stoff som heter Fluid Film. Dette lages hovedsaklig av lanolinolje, som er basert på fett fra saueull." +
                        " Vi rengjør og tørker bilen under, fjerner plastdeksler under bilen og inne i hjulbuer." +
                        " Ekstra utsatte deler og påbegynt rustede deler påføres et litt tykkere stoff." +
                        " Hele undersiden av bilen sprøytes. I tillegg rengjør vil bilen lett utvendig etterpå." + 
                        " Vi er stolte av eget arbeid og vil gjøre vårt ytterste for å hjelpe deg beskytte bilen din mot rust."
                    }
                />
            </ListItem>
        </List>
    );

    const priceInfoProps = {
        image: 'questions.svg',
        imageAlt: 'Priser',
        imageTitle: 'Priser',
        title: 'Priser',
        text: "Vi tilbyr rimelig og rask behandling. Prisen varierer etter størrelsen på bilen.  \nSmå personbiler fra Kr <b>1950,-</b> inkl. mva  \nStore personbiler fra Kr <b>2300,-</b> inkl. mva  \nSUV fra Kr <b>2650,-</b> inkl. mva",
    };

    return (
        <Grid container spacing={3}>
            <Grid item sm={12}>
                <Container maxWidth='md' className={classes.container}>
                    <Card>
                        <CardMedia
                            component="img"
                            alt={priceInfoProps.imageAlt}
                            image={priceInfoProps.image}
                            title={priceInfoProps.imageTitle}
                            className={classes.image}
                        />
                        <CardContent>
                            <Typography
                                component="p"
                                variant="h5"
                                color="textPrimary"
                            >
                                Hvorfor behandle bilen din hos Hellestranda Antirust AS?
                                </Typography>
                            {list}
                        </CardContent>
                    </Card>
                </Container>
            </Grid>
        </Grid>
    );
}