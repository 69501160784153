import { ReactComponent as BobSvg } from '../assets/BobSecondary.svg';
import React from 'react';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

const BobSecondary = (props: {style?: CSSProperties; className?: string}) => {
    return (
        <BobSvg className={props.className} />
    );
}

export { BobSecondary };