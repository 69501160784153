import { Grid, makeStyles, createStyles, Theme, Container, List, ListItem, ListItemIcon, ListItemText, Typography, Card, CardMedia, CardContent } from "@material-ui/core";
import React from 'react';
import { Car } from "../assetComponents/Car";
import { Suv } from "../assetComponents/Suv";
import { Truck } from "../assetComponents/Truck";
import { Link } from "react-router-dom";
import { prices } from '../prices';
import { StationWagon } from "../assetComponents/StationWagon";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        topHomeLogo: {
            width: '100%',
            height: 'auto',
        },
        container: {
            paddingTop: '24px',
            paddingBottom: '24px',
            paddingLeft: '0px',
            paddingRight: '0px'
        },
        icon: {
            height: '3rem',
            width: '3rem'
        },
        image: {
            maxHeight: '400px',
            objectFit: 'fill'
        },
        vehicleColor: {
            fill: theme.palette.primary.main,
            color: theme.palette.primary.main
        }
    })
);

export default function Prices() {
    const classes = useStyles();

    const list = (
        <List>
            <ListItem key="compact-car">
                <ListItemIcon><Car className={classes.icon + " " + classes.vehicleColor} /></ListItemIcon>
                <ListItemText
                    primary={"Liten bil"}
                    secondary={`Fra ${prices.small},- NOK`}
                />
            </ListItem>
            <ListItem key="medium-car">
                <ListItemIcon><StationWagon className={classes.icon} /></ListItemIcon>
                <ListItemText
                    primary={"Mellomstor bil"}
                    secondary={`Fra ${prices.medium},- NOK`}
                />
            </ListItem>
            <ListItem key="suv">
                <ListItemIcon><Suv className={classes.icon} /></ListItemIcon>
                <ListItemText
                    primary={"SUV"}
                    secondary={`Fra ${prices.suv},- NOK`}
                />
            </ListItem>
            <ListItem key="large-car">
                <ListItemIcon><Truck className={classes.icon} /></ListItemIcon>
                <ListItemText
                    primary={"Stor bil / varebil"}
                    secondary={`Fra ${prices.large},- NOK`}
                />
            </ListItem>
        </List>
    );

    const priceInfoProps = {
        image: 'Wallet.svg',
        imageAlt: 'Priser',
        imageTitle: 'Priser',
        title: 'Priser',
        text: `Vi tilbyr rimelig og rask behandling. Prisen varierer etter størrelsen på bilen.  \nSmå personbiler fra Kr <b>${prices.small},-</b> inkl. mva  \nStore personbiler fra Kr <b>${prices.medium},-</b> inkl. mva  \nSUV fra Kr <b>${prices.large},-</b> inkl. mva`,
    };

    return (
        <Grid container spacing={3}>
            <Grid item sm={12}>
                <Container maxWidth='md' className={classes.container}>
                    <Card>
                        <CardMedia
                            component="img"
                            alt={priceInfoProps.imageAlt}
                            image={priceInfoProps.image}
                            title={priceInfoProps.imageTitle}
                            className={classes.image}
                        />
                        <CardContent>
                            <Typography
                                component="p"
                                variant="h5"
                                color="textPrimary"
                            >
                                Priser
                                </Typography>
                            {list}
                            <Typography
                                component="p"
                                variant="body2"
                                color="textPrimary"
                            >
                                Prisene er inklusiv mva
                            </Typography>
                            <Typography
                                component="p"
                                variant="body2"
                                color="textPrimary"
                            >
                                Klar for å bestille, eller er du usikker på hvilken kategori bilen din faller under? <Link to="/contact">Ta kontakt, så hjelper vi deg.</Link>
                            </Typography>
                        </CardContent>
                    </Card>
                </Container>
            </Grid>
        </Grid>
    );
}