import { ReactComponent as AssetSvg } from '../assets/truck.svg';
import React from 'react';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

const Truck = (props: {style?: CSSProperties; className?: string}) => {
    return (
        <AssetSvg className={props.className} />
    );
}

export { Truck };