import { Grid, makeStyles, createStyles, Theme, Container, List, ListItem, ListItemIcon, ListItemText, CardMedia, Card, CardContent, Typography } from "@material-ui/core";
import React from 'react';
import { ViewContentCardProps } from '../components/ViewContentCard';
import LocationOn from '@material-ui/icons/LocationOn';
import AttachMoney from '@material-ui/icons/AttachMoney';
import Phone from '@material-ui/icons/Phone';
import Timer from '@material-ui/icons/Timer';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        topHomeLogo: {
            width: '100%',
            height: 'auto',
        },
        container: {
            paddingTop: '24px',
            paddingBottom: '24px',
            paddingLeft: '0px',
            paddingRight: '0px'
        },
        image: {
            maxHeight: '400px',
            objectFit: 'fill'
        }
    })
);

export default function Contact() {
    const classes = useStyles();

    const contactInfoProps: ViewContentCardProps = {
        image: 'Contact.svg',
        imageAlt: 'Kontakt Informasjon',
        imageTitle: 'Kontakt Informasjon',
        title: 'Kontakt oss for bestilling eller spørsmål',
        text: 'Ønsker du å bestille understellsbehandling?  \n' +
            'Eller har du spørsmål angående behandlingen?  \n' +
            'Ta kontakt så svarer vi på spørsmål eller booker behandling.  \n  \n' +
            'Du kan ringe oss på tlf: <b>952 10 855</b>'
    };

    const list = (
        <List>
            <ListItem key="location">
                <ListItemIcon><LocationOn fontSize='large' color='primary' /></ListItemIcon>
                <ListItemText
                    primary={"Hvor er Hellestranda Antirust AS?"}
                    secondary={
                        <React.Fragment>
                            <Typography
                                component="span"
                                variant="body2"
                                color="textPrimary"
                            >
                                Adresse Vatnefjordvegen 555, Ålesund kommune, 6km fra Vatne.
                            </Typography><br />
                            <Typography
                                component="span"
                                variant="body2"
                                color="textPrimary"
                            >
                                <a
                                    target="_blank"
                                    href="https://www.google.com/maps/search/?api=1&query=Hellestranda+Antirust+Vatne+Norge&query_place_id=ChIJXUxnLoy1FkYRHahgYXG_HjA"
                                >
                                    Søk på Hellestranda Antirust på Google Maps
                                </a>
                            </Typography><br />
                        </React.Fragment>}
                />
            </ListItem>
            <ListItem key="contact">
                <ListItemIcon><Phone fontSize='large' color='primary' /></ListItemIcon>
                <ListItemText
                    primary={"Hvordan bestille?"}
                    secondary={
                        <React.Fragment>
                            <Typography
                                component="span"
                                variant="body2"
                                color="textPrimary"
                            >
                                Ring oss med nummer: <b>952 10 855</b>
                            </Typography>
                        </React.Fragment>}
                />
            </ListItem>
            <ListItem key="price">
                <ListItemIcon><AttachMoney fontSize='large' color='primary' /></ListItemIcon>
                <ListItemText
                    primary={"Hva koster det?"}
                    secondary={
                        <React.Fragment>
                            <Link to="/prices">
                                <Typography
                                    component="span"
                                    variant="body2"
                                >
                                    Trykk her for å se vår prisliste
                                </Typography>
                            </Link>
                        </React.Fragment>}
                />
            </ListItem>
            <ListItem key="time">
                <ListItemIcon><Timer fontSize='large' color='primary' /></ListItemIcon>
                <ListItemText
                    primary={"Hvor lang tid tar det?"}
                    secondary={
                        <React.Fragment>
                            <Typography
                                component="span"
                                variant="body2"
                                color="textPrimary"
                            >
                                Ved levering mellom 16:00 - 18:00, kan bilen hentes neste dag fra 16:00.
                            </Typography>
                        </React.Fragment>}
                />
            </ListItem>
        </List>
    );

    return (
        <Grid container spacing={3}>
            <Grid item sm={12}>
                <Container maxWidth='md' className={classes.container}>
                    <Card>
                        <CardMedia
                            component="img"
                            alt={contactInfoProps.imageAlt}
                            image={contactInfoProps.image}
                            title={contactInfoProps.imageTitle}
                            className={classes.image}
                        />
                        <CardContent>
                            {list}
                        </CardContent>
                    </Card>
                </Container>
            </Grid>
        </Grid>
    );
}