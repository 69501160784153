import { Grid, makeStyles, createStyles, Theme, Container, Typography, Card, CardMedia, CardContent, Link } from "@material-ui/core";
import React from 'react';
import { ViewContentCardProps } from "../components/ViewContentCard";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        topHomeLogo: {
            width: '100%',
            height: 'auto',
        },
        container: {
            paddingTop: '24px',
            paddingBottom: '24px',
            paddingLeft: '0px',
            paddingRight: '0px'
        },
        icon: {
            height: '3rem',
            width: 'auto'
        },
        image: {
            maxHeight: '400px',
            objectFit: 'fill'
        }
    })
);

export default function About() {
    const classes = useStyles();

    const aboutCardProps: ViewContentCardProps = {
        image: 'BobSecondary.svg',
        imageAlt: 'Om oss',
        imageTitle: 'Om oss',
        title: 'Om oss',
        text: ''
    }

    return (
        <Grid container spacing={3}>
            <Grid item sm={12}>
            <Container maxWidth='md' className={classes.container}>
                    <Card>
                        <CardMedia
                            component="img"
                            alt={aboutCardProps.imageAlt}
                            image={aboutCardProps.image}
                            title={aboutCardProps.imageTitle}
                            className={classes.image}
                            
                        />
                        <CardContent>
                            <Typography
                                component="p"
                                variant="h5"
                                color="textPrimary"
                            >
                                Om oss
                            </Typography>
                            <br />
                            <Typography
                                component="p"
                                variant="body2"
                                color="textPrimary"
                            >
                                Hellestranda Antirust AS drives av Karl Johan Fylling.
                                Vi holder til på Hellestranda i Ålesund kommune, ytterst i vatnefjorden omtrent 6km fra Vatne.
                                Vi utfører understellsbehandling av biler med Fluid Film Lanolinolje.
                                Denne metoden er velprøvd, og tusenvis av biler har blitt behandlet med særdeles gode resultater.
                            </Typography>
                            <br />
                            <Typography
                                component="p"
                                variant="body2"
                                color="textPrimary"
                            >
                                Behandlingen gjøres i verkstedet hjemme, der vi har lift som kan løfte biler opp til 4 tonn.
                                Til nå er de største bilene vi har behandlet alt fra VW transporter til Dodge RAM 2500 og Chevrolet Duramax.
                                Vi tar selvølgelig også alle vanlige personbiler og har erfaring med en rekke biler.
                            </Typography>
                            <br />
                            <Typography
                                component="p"
                                variant="body2"
                                color="textPrimary"
                            >
                                Vi utfører et nøye arbeid til lav pris. Ta gjerne kontakt per mail eller telefon om du har spørsmål!   
                            </Typography>
                        </CardContent>
                    </Card>
                </Container>
            </Grid>
        </Grid>
    );
}