import React from 'react';
import { Grid, Box, makeStyles, createStyles, Theme, Container, Paper, Typography, Divider } from "@material-ui/core";
import { LogoPrimary } from '../assetComponents/LogoPrimaryAS';
import { BobSecondary } from './BobSecondary';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        footerRoot: {
            width: '100%'
        },
        container: {
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(8)
        },
        item: {
            textAlign: 'center',
            alignItems: 'center',
        },
        logo: {
            width: '80%'
        }
    })
);


export default function Footer() {
    const classes = useStyles();

    // Org nr: 925 850 764

    return (
        <Box component="div" className={classes.footerRoot}>
            <Divider />
            <Container component="div" className={classes.container}>
                <Grid container spacing={3}>
                    <Grid item md={4} xs={12} className={classes.item}>
                        <Typography variant="body1" className={classes.item}><LogoPrimary className={classes.logo} /></Typography>
                        
                    </Grid>
                    <Grid item md={4} xs={12} className={classes.item}>
                        <Typography variant="h5" className={classes.item}>Kontaktinformasjon</Typography>
                        <Typography variant="subtitle1" className={classes.item}>Telefon: <b>952 10 855</b></Typography>
                        <Typography variant="subtitle1" className={classes.item}>Adresse: <b>Vatnefjordvegen 555, 6265 Vatne</b></Typography>
                        <Typography variant="subtitle1" className={classes.item}>Orgnr: 925 850 764</Typography>
                        <div style={{ height: '24px' }} />
                        <Typography variant="caption" className={classes.item}>© 2023 Johan-Henrik Fylling</Typography>
                    </Grid>
                    <Grid item md={4} xs={12} className={classes.item}>
                         <BobSecondary />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}